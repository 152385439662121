import { FormPropsInterface } from "./typings";

export function Form({loading, onSubmit, children, className = ""}:FormPropsInterface) {
    return (
        <form className={`form-horizontal ${className}`} onSubmit={onSubmit} datatype="multipart/form-data">
            <fieldset disabled={loading}>
                {children}
            </fieldset>
        </form>
    );
}
