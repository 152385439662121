import { useMutate } from "../../hooks/useMutations";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { APP_API_URL } from "../../config/api";
import { useEffect } from "react";
import { SSO_ENABLE } from "../../config/global";
import FullLoaderImg from "../../components/loader/FullLoaderImg";

export default function SignOutPage() {
  /**
   * Hooks
   */
  const navigate = useNavigate();
  const auth = useAuth();

  /**
   * Mutation
   */
  const { mutateAsync: signOut, isLoading } = useMutate(APP_API_URL + `logout`);

  /**
   * Function
   */
  const logout = () => {
    if (SSO_ENABLE) {
      let result = signOut({});
      result
        .then((response) => {
          if (response) {
            if (response.status === 200) {
              auth.signOut();
            } else {
              navigate("/500", { replace: true });
            }
          }
        })
        .catch((e) => {
          console.log(e);
          navigate("/500", { replace: true });
        });
    } else {
      auth.signOut();
    }
  };

  /**
   * Effect
   */
  useEffect(() => {
    if (!isLoading) {
      logout();
    }
  }, []);

  /**
   * UI
   */
  return <FullLoaderImg text="Déconnexion" showImage={false} />;
}
