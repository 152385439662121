import Pagination from "./Pagination";
import Table from "./Table";
import { TablePaginatePropsInterface, ColumnInterface } from "./typings"


export default function TablePaginate({ columns, loading, datas, pagination, setPagination, tableClassName = "" }: TablePaginatePropsInterface) {
    const columnsTables = columns.filter((column: ColumnInterface) => column.isVisible || column.isVisible === undefined);
    return (<>
        <div className={"overflow-x-auto"}>
            <Table columns={columnsTables} tableClassName={tableClassName} loading={loading} datas={datas?.data} />
        </div>
        <Pagination itemsCount={datas?.paginate?.total} itemCountFrom={datas?.paginate?.from} itemCountTo={datas?.paginate?.to}
            pageIndex={pagination?.page} perPage={pagination?.perPage}
            setPerPage={(perPage: number) => setPagination({ page: pagination?.page, perPage: perPage })}
            setPage={(page: number) => setPagination({ page: page, perPage: pagination?.perPage })} />
    </>);
}
