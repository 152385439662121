import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import TopNav from "./TopNav";
import SideNav from "./SideNav";
import { useLayout } from "../../hooks/useLayout";
import { LayoutType } from "../../config/global";

function Main() {
    const { layout } = useLayout();
    return (
        <div id="layout-wrapper">
            <Header />
            <SideNav visible={layout === LayoutType.vertical} />
            <TopNav visible={layout === LayoutType.horizontal} />
            <div className="main-content">
                <div className="page-content overflow-y-auto">
                    <div className="px-4">
                        <Outlet />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Main;
