import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getStorageLang } from "../hooks/useStorage";
import resourcesToBackend from 'i18next-resources-to-backend';


export const defaultNS = getStorageLang();

i18n.use(initReactI18next)
  .use(resourcesToBackend((language: string, namespace: string) => import(`../../public/locales/${language}/${namespace}.json`)))
  .init({
    lng: getStorageLang(),
    fallbackLng: 'fr',
    ns: ["menu", "page", "button"],
  });

export default i18n;