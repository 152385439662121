import Loader from "./Loader";
import img from "../../assets/images/coming-soon.svg";

export default function FullLoaderImg({
  text,
  showImage = true,
}: {
  text: string;
  showImage?: boolean;
}) {
  return (
    <div className="grid h-screen place-items-center ">
      <div className="w-96 mx-auto my-auto">
        {showImage && <img src={img} alt="" className="img-fluid" />}
        <span className="block text-center text-xl mb-2">{text}</span>
        <Loader type="bar" />
      </div>
    </div>
  );
}
