import { SkeletonInterface } from "./typings";

export default function TableSkeleton({ numberOfElement, numberOfColumn }: SkeletonInterface) {
    const elements: JSX.Element[] = [];
    let simpleElement: JSX.Element;
    let simpleRow: JSX.Element[];
    for (let i: number = 0; i < numberOfElement; i++) {
        simpleRow = []
        for (let j: number = 0; j < numberOfColumn; j++) {
            simpleRow.push((
                <td key={j} className={"h-12"}>
                    <div className={"bg-soft-secondary p-4 animate-pulse"}></div>
                </td>
            ))
        }
        simpleElement = (
            <tr key={i}>
                {simpleRow}
            </tr>
        );
        elements.push(simpleElement);
    }
    return <>
        {elements}
    </>
}