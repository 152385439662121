import { Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import AuthProvider from "./context/auth/AuthProvider";
import RequireAuth from "./middleware/RequireAuth";
import ManageRoute from "./middleware/ManageRoute";
import Main from "./components/layout/Main";
import SignWithAccessToken from "./pages/auth/SignWithAccessToken";
import SignOutPage from "./pages/auth/SignOutPage";
import ServerError from "./pages/error/500";
import LayoutProvider from "./context/layout/LayoutProvider";
import ClientPage from "./pages/ClientPage";
import SessionPage from "./pages/SessionPage";
import PaymentPage from "./pages/DashboardPage";

function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <LayoutProvider>
          <Routes>
            <Route element={<RequireAuth />}>
              <Route element={<Main />}>
                <Route path="clients" element={<ClientPage />} />
                <Route path="sessions" element={<SessionPage />} />
                <Route path="dashboard" element={<PaymentPage />} />
              </Route>
              <Route path="logout" element={<SignOutPage />} />
            </Route>
            <Route path="auth/login" element={<SignWithAccessToken />} />
            <Route path="500" element={<ServerError />} />
            <Route path="*" element={<ManageRoute />} />
          </Routes>
        </LayoutProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
