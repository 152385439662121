import { useEffect, useState } from "react";
import LayoutContext from "./LayoutContext";
import { LayoutType } from "../../config/global";
import useStorage from "../../hooks/useStorage";

function LayoutProvider({ children }: { children: React.ReactNode }) {
    const { getStorageLayout, setStorageLayout } = useStorage();
    const [layout, setLayout] = useState(getStorageLayout());
    const changeLayout = (layout: LayoutType) => {
        document.body.setAttribute("data-layout", layout);
        setLayout(layout)
        setStorageLayout(layout)
    }
    useEffect(() => {
        changeLayout(layout)
    }, [])

    return <LayoutContext.Provider value={{ layout: layout, changeLayout: changeLayout }}>
        {children}
    </LayoutContext.Provider>
}
export default LayoutProvider