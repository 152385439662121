import { useMutation, useQueryClient } from "react-query";
import useRequest from "./useRequest";
import { toast } from "react-toastify";
import { RequestType } from "../utils/typings";

export const useMutate = (url: string, type: RequestType = "post") => {
  const { request } = useRequest(url, type);
  return useMutation((payloads: any) => request(payloads), {
    onError: () => {
      toast.error(
        "Une erreur es survenue, nous travaillons pour un rétablissement à la normale au plus vite"
      );
    },
  });
};

export const useMutateWithInvalidateQueries = (
  url: string,
  type: RequestType = "post",
  queryKey: (string | number | undefined)[],
  headers?: object
) => {
  const queryClient = useQueryClient();
  const { request } = useRequest(url, type, headers);
  return useMutation((payloads: any) => request(payloads), {
    onSuccess: () => {
      return queryClient.invalidateQueries(queryKey);
    },
    onError: (error: any) => {
      toast.error(error.response.data.message);
    },
  });
};

export const useMutateWithSetQueriesData = (
  url: string,
  type: RequestType = "post",
  queryKey: (string | number | undefined)[],
) => {
  const queryClient = useQueryClient();
  const { request } = useRequest(url, type);
  return useMutation((payloads) => request(payloads), {
    onSuccess: (data: any) => {
      return queryClient.setQueryData(queryKey, data);
    },
    onError: (error: any) => {
      toast.error(error.response.data.message);
    },
  });
};
