import { NUMBERS_OF_ROWS_LOADER } from "../../config/global";
import TableSkeleton from "../skeleton/TableSkeleton";
import { ColumnInterface, TablePropsInterface } from "./typings";

export default function Table({tableClassName = "", columns, loading, datas}: TablePropsInterface) {
    const columnsTables = columns.filter((column: ColumnInterface) => column.isVisible || column.isVisible === undefined);
    return (
        <table className={`w-full border-collapse border border-slate-500 ${tableClassName}`}>
            <thead>
                <tr>
                    {
                        columnsTables.map((column: ColumnInterface) => {
                            return (<th className={column.className ?? ""} key={column.key}>{column.title}</th>)
                        })
                    }
                </tr>
            </thead>
            <tbody>
                {loading ? <TableSkeleton numberOfElement={NUMBERS_OF_ROWS_LOADER} numberOfColumn={columnsTables.length} />
                    : (
                        datas?.map((data: any, index: number) => {
                            return (<tr key={"rows-" + index}>
                                {
                                    columnsTables.map((column: ColumnInterface, indexColumn: number) => {
                                        if (column.hasOwnProperty("render") && column.render) {
                                            return <td className={column.className ?? ""} key={"cols-" + indexColumn}>{column.render(data, index)}</td>;
                                        } else if (data.hasOwnProperty(column.dataIndex)) {
                                            if (!column.hasOwnProperty("render") && column.dataIndex) {
                                                return (<td className={column.className ?? ""} key={"cols-" + indexColumn}>{data[column.dataIndex]}</td>)
                                            }
                                        }
                                        return <td className={column.className ?? ""} key={"cols-" + indexColumn}></td>;
                                    })
                                }
                            </tr>);
                        })
                    )}
            </tbody>
            {(datas?.length === 0 && !loading) && (<tfoot>
                <tr>
                    <th colSpan={columnsTables.length} className={"text-center bg-light"}>
                        Liste vide
                    </th>
                </tr>
            </tfoot>)}
        </table>
    )
}