import { useState } from "react";
import TemplateDropdown from "./TemplateDropdown";
import { DropdownPropsInterface, ItemDropdownInterface } from "./typings";

export default function Dropdown({
  items,
  onOpen,
  buttonChildren,
  onClose,
  className = "",
  buttonClassName = "",
  dataToItem = null,
  type = "dropdown",
}: DropdownPropsInterface) {
  const [open, setOpen] = useState(false);
  const itemsDropdowns = items?.filter(
    (item: ItemDropdownInterface) =>
      item.isVisible || item.isVisible === undefined
  );

  return (
    <TemplateDropdown
      open={open}
      setOpen={setOpen}
      onOpen={onOpen}
      onClose={onClose}
      buttonChildren={buttonChildren}
      className={`${className}`}
      buttonClassName={buttonClassName}
      type={type}
    >
      {itemsDropdowns?.map((item, index) => {
        return (
          <span key={index}>
            <button
              onClick={() => {
                dataToItem ? item.onClick(dataToItem) : item.onClick();
                setOpen(false);
              }}
              className={`dropdown-item flex items-center hover:bg-opacity-5 hover:bg-gray-500 ${item.className}`}
            >
              {item.children()}
            </button>
            {item?.divider && <div className={"dropdown-divider"}></div>}
          </span>
        );
      })}
    </TemplateDropdown>
  );
}
