import {useForm} from "react-hook-form";
import {APP_API_URL} from "../config/api";
import Card from "../components/card/Card";
import {useQueries} from "../hooks/useQueries";
import {useRef} from "react";
import {Form} from "../components/form/Form";
import SimpleButton from "../components/button/SimpleButton";
import {BiSearchAlt2} from "react-icons/bi";
import moment from "moment";
import {InputDate, InputText} from "../components/form/Input";
import ReactApexChart from "react-apexcharts";
import {CONFIG_CHART} from "../components/chart/configChart";
import {Select2} from "../components/form/Select";
import CardTable from "../components/card/CardTable";

export default function DashboardPage() {
    /**
     * Hooks
     */

    const fromDate = useRef(moment().subtract(7, "day").format("YYYY-MM-DD"));
    const toDate = useRef(moment().format("YYYY-MM-DD"));

    const {
        register,
        handleSubmit,
        control,
        formState: {errors},
    } = useForm();


    /**
     * Query
     */
    const {data: clients} = useQueries(
        APP_API_URL +
        `stats`,
        []
    );
    const {data: sessions} = useQueries(
        APP_API_URL +
        `stats`,
        []
    );
    const {data: sessionsRef} = useQueries(
        APP_API_URL +
        `stats`,
        []
    );

    const handleSubmitFilterForm = (values: any) => {
        fromDate.current = values.fromDate;
        toDate.current = values.toDate;
    };

    /**
     * Columns Table
     */

    /**
     * UI
     */

    return (
        <>
            <div className="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
                <Card title="" className="font-size-22">
                    <div className="text-blue-600">TOTAL DES CLIENTS</div>
                    <div className="text-right w-full">
                        <div className="">
                            {sessionsRef?.data.data.map((data: any) => data.sessionsRef)}
                        </div>
                    </div>
                </Card>
                <Card title="" className="font-size-22">
                    <div className="text-blue-600">TOTAL DE FACTURES TELECHARGÉES</div>
                    <div className="text-right w-full">
                        <div className="">
                            {clients?.data.data.map((data: any) => data.clients)}
                        </div>
                    </div>
                </Card>
            </div>

        </>
    );
}