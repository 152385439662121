import { UserRoles } from "../config/global";
import { useAuth } from "../hooks/useAuth";

const CheckRole = (roles: UserRoles[] | undefined): boolean => {
    const auth = useAuth();
    if (roles && roles.length > 0) {
        return auth.user.roles.slice().filter((userRole: UserRoles) => roles.find((role) => role === userRole)).length > 0 ? true : false;
    }
    return true;
}

export default CheckRole